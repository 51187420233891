
import Vue from "vue";
import API from "@/api/API";
import menu from "../config/menu.json";
import ThemeSettings from "@/components/settings/ThemeSettings.vue";

export default Vue.extend({
  name: "PaymentSuccess",

  components: {
    dashboard: () =>
      import(/* webpackChunkName: "Users" */ "../components/Dashboard.vue"),
    security: () =>
      import(/* webpackChunkName: "Profiles" */ "../components/Security.vue"),
    personal: () =>
      import(/* webpackChunkName: "Devices" */ "../components/Personal.vue"),
    clients: () =>
      import(/* webpackChunkName: "Devices" */ "../components/Clients.vue"),
    ThemeSettings
  },

  data: () => ({
    menu,
    themeSettingsSidebar: true as boolean,
    verificationStep: 2 as number,
    selects: {
      roles: []
    } as any
  }),

  computed: {
    currentComponent() {
      return this.$options.components?.[this.$route.params.action];
    },
    user() {
      return this.$store.getters["user/info"];
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const rolesList = await API.users().getRoles();
      next(vm => {
        vm.selects.roles = rolesList;
      });
    } catch (e) {
      console.log(e);
    }
  },

  methods: {
    toggleThemeSettings() {
      this.themeSettingsSidebar = !this.themeSettingsSidebar;
    },
    async loadData(): Promise<any> {
      try {
        this.selects.roles = await this.$API.users().getRoles();
      } catch (e) {
        await this.$store.dispatch("alerts/showError", e.message);
      }
    },
    async logout(): Promise<void> {
      try {
        await this.$store.dispatch("authentication/logout");
        document.location.href = origin;
      } catch (e) {
        await this.$store.dispatch("alerts/showError", e.message);
      }
    }
  }
});
