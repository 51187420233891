var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height pa-6"},[_c('div',{staticClass:"grid-container"},[_c('div',[_c('v-card',{staticClass:"fixed",attrs:{"elevation":"2"}},[_c('v-card-text',[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-icon',{staticClass:"mr-2",attrs:{"size":"18","color":"primary"}},[_vm._v(" mdi-check ")])],1),_c('div',{staticClass:"text-center"},[_c('v-avatar',{staticStyle:{"border":"4px solid"},attrs:{"size":"148","color":"primary"}},[(_vm.user.avatar)?_c('v-img',{attrs:{"src":_vm.user.avatar.url}}):_c('span',{staticClass:"white--text font-weight-bold",staticStyle:{"font-size":"64px"}},[_vm._v(" "+_vm._s(_vm.user.login .split(" ") .map(function (ref) {
            var item = ref[0];

            return item;
}) .slice(0, 2) .join("") .toUpperCase())+" ")])],1)],1),_c('div',{staticClass:"font-weight-bold body-1 text-center mt-8"},[_c('span',[_vm._v(" "+_vm._s(_vm.user.login)+" ")])]),(_vm.selects.roles.length)?_c('div',{staticClass:"body-2 text-center mb-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.selects.roles.find( function (item) { return item.value === _vm.user.role_level.toString(); } ).text)+" ")])]):_vm._e()]),_c('v-list',{staticClass:"pr-3",attrs:{"shaped":""}},[_vm._l((_vm.menu),function(item,index){return _c('v-list-item',{key:index,attrs:{"color":"primary","to":item.goTo}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)}),_c('v-divider',{staticClass:"my-5 mr-2"}),_c('v-list-item',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleThemeSettings()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Настройки")])],1)],1),_c('v-list-item',{directives:[{name:"confirm",rawName:"v-confirm",value:({
              title: 'Выход',
              message: 'Вы уверены что хотите выйти?',
              callback: function () { return _vm.logout(); }
            }),expression:"{\n              title: 'Выход',\n              message: 'Вы уверены что хотите выйти?',\n              callback: () => logout()\n            }"}],attrs:{"color":"primary"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Выйти")])],1)],1)],2)],1)],1),_c('div',[_c('transition',{attrs:{"name":"fade"}},[_c(_vm.currentComponent,{tag:"component",attrs:{"user":_vm.user}})],1)],1)]),_c('theme-settings',{attrs:{"toggled":_vm.themeSettingsSidebar},on:{"closeThemeSettings":_vm.toggleThemeSettings}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }